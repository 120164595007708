import * as myftUi from '@financial-times/n-myft-ui/myft';
import Expander from '@financial-times/o-expander';
import Video from '@financial-times/o-video';
import nClip from '@financial-times/n-clip';
import Clip  from '@financial-times/cp-content-pipeline-ui/lib/components/Clip/client';
import Audio from '@financial-times/o-audio';
import oDate from '@financial-times/o-date';
import { magnetInit } from '@financial-times/n-magnet';
import oErrors from '@financial-times/o-errors';

import componentTracking from './components/tracking/main';
import legalCopy from './components/legal-copy/main';
import * as seriesStickyNav from './components/series-sticky-nav';
import Feedback from './components/feedback';
import progressIndicator from './components/progress-indicator';
import initTables from './components/table/main';
import { initLiveBlogPost } from './components/live-blog-post';
import * as printButton from './components/print-button';
import * as nExponea from '@financial-times/n-exponea';
import workspaceNavInit from '@financial-times/workspace-menu';

// custom HTML Element for the custom code component
import '@financial-times/custom-code-component/dist/custom-code-component.js';

// Page Kit
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';

// Tracking
import * as nTracking from '@financial-times/n-tracking';

import readyState from 'ready-state';
import scrollytelling from './components/scrollytelling';

// In Article Content Sign Up
import oOverlay from '@financial-times/o-overlay';
import signUpConfirmation from './components/sign-up/confirmation';

// Google Extended Access
import { getUserAndAccessExtendedInfo } from './helpers/google-extended-access';
import { isUserExtendedAccessEligible } from '../server/share/google-extended-access';

const SIGN_UP_CONFIRMATION_OVERLAY_ID = 'signUpConfirmationOverlay';
const clientOpts = [];

readyState.dom.then(() => {
	const flags = pageKitFlags.init();
	const appContext = pageKitAppContext.init();

	let oTracking;
	const headerOptions = flags.get('enhancedSearchTest')
		? { enhancedSearchUrl: 'https://enhanced-search.ft.com/?q=', searchState: 'open' }
		: {};

	pageKitLayout.init({ headerOptions });

	if (flags.get('oTracking')) {
		oTracking = nTracking.init({ appContext: appContext.getAll() });
		componentTracking(oTracking);
	}

	if (flags.get('definitionFeature')) {
		const article = appContext.get('contentType') === 'article';
		const scrollytellingElements = document.querySelector(
			'[data-component="scrollytelling-image"]'
		);
		if (article && !scrollytellingElements) {
			import('@financial-times/n-definitions/client').then(( { init: nDefinitionsInit }) => {
				nDefinitionsInit();
			});
		}
	}

	if (flags.get('enterpriseSharingTooltipsAndLabels')) {
		import('./components/enterprise-share/tooltip').then(({ initSharingTooltip }) => {
			initSharingTooltip();
		});
	}

	if (flags.get('podcastReferral')) {
		import('./components/play-article/main').then(({ init: playArticleInit }) => {
			playArticleInit();
		});
	}

	//getting rootId to pass it to the subscription sign-up iframe to be consistent between root_id
	const trackingRootId = oTracking?.getRootID() || null;
	const signUpArticleIframe = document.getElementById('signUpIframe');
	if (signUpArticleIframe) {
		const url = new URL(
			signUpArticleIframe.getAttribute('data-prev-url'),
			window.location.origin
		);
		if (trackingRootId) {
			url.searchParams.append('trackingRootId', trackingRootId);
		}
		signUpArticleIframe.setAttribute('src', url.href);
	}

	if (flags.get('qualtrics')) {
		import('@financial-times/n-feedback').then(({ init: nFeedbackInit }) => {
			nFeedbackInit({ ...appContext.getAll() });
		});
	}

	if (flags.get('realUserMonitoringForPerformance')) {
		nTracking.trackers.realUserMonitoringForPerformance();
	}

	if (flags.get('optimizely') && flags.get('optimizelyArticle')) {
		// The Optimizely snippet attaches relevant experiment details to the window object

		let i = 0;
		const getActiveTests = () => {
			i++;
			const activeTests =
				window.FT &&
				window.FT.optimizelyAdditionalDetails &&
				window.FT.optimizelyAdditionalDetails.spoorTracking;
			if (activeTests) {
				activeTests.forEach((test) => {
					nTracking.broadcast('oTracking.event', {
						category: 'optimizely',
						action: 'experiment',
						...test
					});
				});
				clearInterval(intervalId);
			}
			if (i >= 5) {
				nTracking.broadcast('oTracking.event', {
					category: 'optimizely',
					action: 'no-experiment'
				});
				clearInterval(intervalId);
			}
		};
		const intervalId = setInterval(getActiveTests, 100);
	}

	if (flags.get('syndication')) {
		import('@financial-times/n-syndication').then(({ init: nSyndicationInit }) => {
			nSyndicationInit(flags);
		});
	}

	if (flags.get('myFtApi')) {
		clientOpts.push({ relationship: 'followed', type: 'concept' });
		clientOpts.push({ relationship: 'saved', type: 'content' });
	}

	myftUi.client.init(clientOpts).catch((e) => {
		// Keeps console clean for anonymous users
		if (e !== 'No session cookie found') {
			throw e;
		}
	});

	if (flags.get('articleShareButtons')) {
		import('./components/share/main').then(({ init: shareInit }) => {
			shareInit(flags);
		});
	}

	workspaceNavInit({
		app: appContext.appName || 'article',
		enabled: !flags.get('myFtWorkspaceTest') && flags.get('enterpriseWorkspaceNav'),
		navTarget: '[data-component="nav-list--right"]',
		drawerTarget: '[data-component="drawer-menu--primary__drawer-menu-list"]',
		drawerDividerTarget: '[data-component="drawer-menu-item--divide"]'
	}).catch((error) => {
		// eslint-disable-next-line no-console
		console.error(
			'Workspace navigation failed to initialise, please inform LifeCycle team in Enterprise',
			error
		);
	});

	//strip out the imageId query parameter so it doesn't get duplicated when sharing graphics again
	if (
		window.location.search &&
		window.location.search.indexOf('imageId=') >= 0
	) {
		window.history.replaceState(
			null,
			null,
			window.location.pathname +
				window.location.search.replace(
					/&imageId(=[^&]*)?|^\?imageId(=[^&]*)?&?/,
					''
				)
		);
	}

	nTracking.trackers.pageAttention({ target: '.article__content' });

	if (flags.get('textCopyTracking')) {
		nTracking.trackers.textCopying();
	}

	legalCopy(flags);

	if (flags.get('enterpriseUserAnnotations')) {
		const initOptions = {
			readOnly: !flags.get('enterpriseUserAnnotationsEditor'),
			highlightsRelatedContent: flags.get('highlightsRelatedContent')
		};
		import('@financial-times/user-annotations-editor').then(
			({ default: userAnnotationsEditor }) => {
				userAnnotationsEditor.init(initOptions);
			});
	}

	seriesStickyNav.init();

	if (document.querySelector('.o-expander__toggle')) {
		Array.from(
			document.body.querySelectorAll('[data-o-component="o-expander"]'),
			(rootEl) => {
				const userOptions = Expander._getDataAttributes(rootEl);

				const opts = Object.assign(userOptions, {
					selectors: {
						toggle: '.o-expander__toggle',
						content: '.o-expander__content',
						item: userOptions.itemSelector || 'li'
					},
					classnames: {
						initialized: 'o-expander--initialized',
						inactive: 'o-expander--inactive',
						expanded: 'o-expander__content--expanded-next-article',
						collapsed: 'o-expander__content--collapsed',
						collapsibleItem: 'o-expander__collapsible-item'
					}
				});

				return Expander.createCustom(rootEl, opts);
			}
		);
	}

	myftUi.ui.init({
		anonymous: !/FTSession=/.test(document.cookie),
		flags
	});

	if (flags.get('myFTInstantAlertsOnboarding')) {
		import('@financial-times/n-myft-ui/components/jsx/preferences-modal').then(({ default: preferencesModal }) => {
			preferencesModal();
		});
		import('@financial-times/n-myft-ui/components/jsx/follow-plus-instant-alerts').then(({ default: followPlusInstantAlerts }) => {
			followPlusInstantAlerts();
		});
	}
	
	oDate.init();

	[...document.querySelectorAll('[data-o-component="o-video"]')].forEach(
		(videoEl) => {
			new Video(videoEl, {
				advertising: flags.get('ads') && flags.get('videoPlayerAdvertising'),
				placeholder: true,
				optimumwidth: 700,
				optimumvideowidth: 1280,
				systemcode: 'next-article'
			});
		}
	);
	// nClip supports the usage of content from ElasticSearch. Temporarily used in case of a failure in the Content Pipeline API.
	// It will be removed once the Content Pipeline API is considered fully stable and fallback to ES won't be anymore a possible option.
	nClip.init(null, {
		fallbackImage: flags.get('clipsFallbackFeature') !== 'variant'
	});
	Clip.init(null, {
		fallbackImage: flags.get('clipsFallbackFeature') !== 'variant'
	});

	// video anchors are added for core experience only
	// remove video anchors when JS is enabled
	document
		.querySelectorAll('a[data-asset-type="video"][data-embedded="true"]')
		.forEach((videoAnchorEl) => {
			videoAnchorEl.parentNode.removeChild(videoAnchorEl);
		});

	[...document.querySelectorAll('[data-o-component="o-audio"]')].forEach(
		(audioEl) => {
			new Audio(audioEl);
		}
	);

	const isSignedIn = document.cookie.includes('FTSession');

	if (isSignedIn && flags.get('magnetOnArticle')) {
		magnetInit(flags)
			.then(() => {
				const replacedItem = document.querySelector('.js-instant-alert-cta');
				replacedItem.style.display = 'none';
			})
			.catch((err) => {
				// fail silently
				// eslint-disable-next-line no-console
				console.debug('failed to init magnet', err);
			});
	}

	if (
		flags.get('articleComments') &&
		document.querySelector('#o-comments-stream')
	) {
		import('./components/comments').then(({ init: commentsInit }) => {
			commentsInit(flags);
		});
	}

	if (nExponea.canShowExponea(flags)) {
		nExponea.init();
	}

	if (
		!flags.get('adsDisableInternalCMP') &&
		(flags.get('messageSlotBottom') || flags.get('messageSlotTop'))
	) {
		import('@financial-times/n-messaging-client/main-client').then(({ nMessagingClient }) => {
			nMessagingClient.init();
		});
	}

	// Turn on the cmp code using the cmp-client package, behind the adsDisableInternalCMP flag
	if (flags.get('adsDisableInternalCMP')) {
		import('@financial-times/cmp-client').then(({ initSourcepointCmp, updateFooterLinkCMP, properties }) => {
			initSourcepointCmp({
				propertyConfig: properties.FT_DOTCOM_PROD
			});
			updateFooterLinkCMP();
		});
	}
	  

	if (flags.get('articleRelatedContent')) {
		import('./components/onward-journey').then(({ init: onwardJourneyInit }) => {
			onwardJourneyInit(flags).catch((err) => oErrors.report(err));
		});
	}

	if (document.querySelector('.twitter-tweet')) {
		import('./components/embedded-tweets').then(({ default: initialiseEmbeddedTweets }) => {
			initialiseEmbeddedTweets();
		});
	}

	progressIndicator();

	if (flags.get('onboardingMessaging') === 'followPlusEmailDigestTooltip') {
		import('./components/onboarding-tooltip').then(({ init: onboardingTooltipInit }) => {
			onboardingTooltipInit();
		});
	}

	new Feedback('article-summary');

	if (flags.get('articleInstantAlertsPromo')) {
		import('./components/pre-body-promotional-banner/main').then(({ default: preBodyPromotionalBanner }) => {
			preBodyPromotionalBanner();
		});
	}

	if (flags.get('myftArticleInstantAlertsCta')) {
		import('./components/pre-body-promotional-banner/instant-alert-cta').then(({ default: instantAlertCta }) => {
			instantAlertCta();
		});
	}

	scrollytelling(flags);

	if (flags.get('useFlourish')) {
		import('./components/flourish/replace-graphics').then(({ default: replaceWithFlourish }) => {
			replaceWithFlourish(appContext);
		});
	}

	if (flags.get('ads')) {
		// Initialise ads last to minimise risk of interference from 3rd party scripts
		// Skip initialising legacy OAds if we're using PG
		import('../utils/ads').then(({ adsUsePGClient }) => {
			if (adsUsePGClient(flags) === false) {
				import('./components/ads').then(({ init: adsInit }) => {
					adsInit(appContext, flags);
				});
			}
		});
	}

	initLiveBlogPost();

	initTables();

	printButton.init();

	// In article content sign up confirmation popup using origami o-overlay component
	const pageOverlays = oOverlay.init();
	const signUpConfirmationOverlay = pageOverlays.find(
		(overlay) => overlay.id === SIGN_UP_CONFIRMATION_OVERLAY_ID
	);

	if (signUpConfirmationOverlay) {
		// render the popup
		signUpConfirmationOverlay.open();

		// attaching submit button behaviour after the overlay ends rendering
		document.addEventListener('oOverlay.ready', (overlayReadyEvent) => {
			if (overlayReadyEvent.detail.el.id === SIGN_UP_CONFIRMATION_OVERLAY_ID) {
				signUpConfirmation(signUpConfirmationOverlay);
			}
		});
	}

	if (flags.get('inArticleContentSignUp')) {
		import('./components/sign-up/details').then(({ addSignupDetailsListeners, registerOnLoadHandler }) => {
			registerOnLoadHandler();
			addSignupDetailsListeners();
		});
	}

	if (flags.get('enterpriseSurveys')) {
		try {
			import('./components/enterprise-surveys/qualtrics-survey').then(({ initEnterpriseSurveys }) => {
				initEnterpriseSurveys();
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(
				'Enterprise Survey failed to initialise please report to Enterprise Services and Tools. ',
				e
			);
		}
	}

	// Google Extended Access initialization
	if (isUserExtendedAccessEligible(window.location.search, flags.flags)) {
		getUserAndAccessExtendedInfo(appContext.appContext)
			.then(({ userState, showcaseEntitlement }) =>
				window.InitGaaMetering(userState, showcaseEntitlement)
			)
			.catch((err) => oErrors.report(err));
	}
});
